import React from "react";
import "./Home.css";
import { Typewriter } from "react-simple-typewriter";
import logo from "../Images/nebula_main.svg";
const Home = () => {
  return (
    <>
      <section className="hero" id="home">
        <div className="container main-menu">
          <div className="left top">
            <h3>Welcome to our company</h3>
            <h1>
              We Are <span>Nebula</span>
            </h1>
            <h2>
              a
              <span>
                <Typewriter
                  words={["  Galaxies of Code.", " Planetary Developers."]}
                  loop
                  cursor
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </h2>
            <p>
              Navigating galaxies of code, Nebula-dev crafts constellations of
              innovation
            </p>

        
          </div>
          <div className="right">
              <img src={logo} alt="logo" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
