import React, { useState } from "react";
import "./Header.css";
import logo from "../Images/nebula_logo.svg";

const Header = () => {
  // window.addEventListener("scroll", function () {
  //   const header = document.querySelector(".header");
  //   header.classList.toggle("active", window.scrollY > 100);
  // });

  const [Mobile, setMobile] = useState(false);
  return (
    <>
      <header className="header">
        <div className="container d_flex">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="navLink">
            <ul
              className={Mobile ? "nav-links-mobile" : "link f_flex uppercase"}
              onClick={() => setMobile(false)}
            >
              <li>
                <a href="#home">home</a>
              </li>
              <li>
                <a href="#service"> Service</a>
              </li>
              <li>
                <a href="#portfolio">portofolio</a>
              </li>

              <li>
                <button className="btn"  >
                  <a href="#contact">

                  Contact now
                  </a></button>
              </li>
            </ul>

            <button className="toggle " onClick={() => setMobile(!Mobile)}>
              {Mobile ? (
                <i className="fas fa-times close  home-btn"></i>
              ) : (
                <i className="fas fa-bars open"></i>
              )}
            </button>
          </div>
        </div>
      </header>

    </>
  );
};

export default Header;
