

import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import contact from '../Images/contact.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'aos/dist/aos.css';
import AOS from 'aos';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css';

export const Contact = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    emailjs
      .sendForm('service_7xw8xl9', 'template_dkqz87e', form.current, 'nUUji8kP8r5vp6xcw')
      .then((result) => {
        console.log(result.text);
        toast.success('Your message has been sent');
      })
      .catch((error) => {
        console.log(error.text);
        toast.error('An error occurred while sending your message');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <section className='contact' id='contact'>
      <div className="textContact">
        <h3>Contact Us</h3>
      </div>
      <div className="mainContact">
        <div className="gifContact">
          <img src={contact} alt="" />
        </div>
        <div className="formContact">
          <form ref={form} onSubmit={sendEmail}>
            <label>Name:</label>
            <input type="text" placeholder="Enter your name" name="from_name" required />
            <label>Email:</label>
            <input type="email" placeholder="Enter your email" name="from_email" required />
            <label>Message:</label>
            <textarea name="message" placeholder="Enter your message" rows="4" cols="40" required />
            
            <input
              type="submit"
              className="sendContact"
              value={isSubmitting ? 'Sending...' : 'Send'}
              disabled={isSubmitting}
            />
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </section>
   
  );
};

export default Contact;
