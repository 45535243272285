import "./Footer.css";

const Footer = () => {  
  return (
    <div className="footer">
      <div className="aboutFooter">
      
      <h5>Find Us</h5>
      <div className="ico1">
          <a href="https://www.facebook.com/profile.php?id=61551110012786" target="_blank" rel="facebook"> 
            <i class="fa-brands fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/nebulaa_dev" target="_blank" rel="instagram">
            <i class="fa-brands fa-instagram "></i>
          </a>
          </div>
          <div className="ico2">
          <a href="https://www.linkedin.com/company/nebuladev/ " target="_blank" rel="linkedin">
            <i class="fa-brands fa-linkedin "></i>
          </a>
          <a href="https://github.com/nebulaa-dev" target="_blank" rel="github">
          <i class="fa-brands fa-github"></i>
          </a>
   </div>
      </div>
      <div className="quickContact">
        <h5>Quick Links</h5>
        <ul>
          <li className="nav-item">
            <a className="nav-link" href="#home">
              Home{" "}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#portfolio">
              Our Work{" "}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#service">
              Services{" "}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">
              Contact{" "}
            </a>
          </li>
        </ul>
      </div>
      <div className="infoContact">
        <h5>Contact Info</h5>
        
        <p>
          <i className="fa-solid fa-envelope"></i> info@nebula-dev.com
        </p>
        <p>
        <i class="fa-solid fa-location-dot"></i> Prishtine
        </p>
      </div>
     
    </div>
    
  );
};

export default Footer;
