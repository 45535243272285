// PortfolioModal.js
import React from 'react';
import './Portfolio.css';

const PortfolioModal = ({ isOpen, onClose, imageSrc, description, links}) => {
  if (!isOpen) return null;

  return (
    <div className="portfolio-modal-container">
      <div className="portfolio-modal-content">
        <div className='close'>
        <span  onClick={onClose}>
          &times;
        </span>
        </div>
        <img src={imageSrc} alt="Modal" className="modal-image" />
        <div className="desc">
          <p className="modal-description">{description}</p>
          
          <div className="bts">
         
            <a className="bt1" href={links}target='_blank'><i class="fa-solid fa-heart"></i></a>
            <a className="bt1" href={links}target='_blank'><i class="fa-solid fa-globe"></i></a>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioModal;
