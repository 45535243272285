import React from "react";
import "./Service.css";
const Service = () => {
  return (
    <section className="service component_space" id="service">
      <div className="heading">
        <h1 className="heading">Our Services</h1>
      </div>
      <div className="main">
        <div className="col_3">
          <div className="service_box pointer">
            <div className="icon">
              <i class="fa fa-brush"></i>
            </div>
            <div className="service_meta">
              <h1 className="service_text">Logo Design </h1>
              <p className="p service_text p_color">Our Logo Design service is a creative journey where we craft unique visual identities for your brand. Using tools like Adobe Illustrator, Adobe Photoshop, and Canvas, we bring your ideas to life. We understand the significance of a strong logo in representing your brand, 
              and we're here to ensure your logo stands out, making a lasting impression.</p>
           
            </div>
          </div>
        </div>
        <div className="col_3">
          <div className="service_box pointer">
            <div className="icon">
              <i class="fa fa-code"></i>
            </div>
            <div className="service_meta">
              <h1 className="service_text">Web Development</h1>

              <p className="p service_text p_color">
              Our Web Development service is your gateway to a dynamic online presence. We specialize in crafting websites using HTML, CSS, Tailwind, Javascript, ReactJs, and VueJs, tailored to your needs. With expertise in MySQL and MongoDB, we ensure robust functionality,
               and we can assist with domain and hosting to make your web project a reality.
              </p>
          
            </div>
          </div>
        </div>
        <div className="col_3">
          <div className="service_box pointer">
            <div className="icon">
              <i class="fa fa-code"></i>
            </div>
            <div className="service_meta">
              <h1 className="service_text">Static Website</h1>
              <p className="p service_text p_color">For a clean and elegant online presence, our Static Website service is the perfect choice. Using a combination of HTML, CSS, Tailwind, Javascript, ReactJs, and VueJs, we create websites that are easy to navigate and visually pleasing.
               Our static websites are designed for efficiency and speed, ensuring a seamless user experience.</p>
      
            </div>
          </div>
        </div>
        <div className="col_3">
          <div className="service_box pointer">
            <div className="icon">
              <i class="fa-solid fa-share-nodes"></i>
             
            </div>
            <div className="service_meta">
              <h1 className="service_text">Social Media </h1>
              <p className="p service_text p_color">
              Our Social Media Management service takes the hassle out of maintaining a vibrant online presence. We handle the intricacies of managing and maintaining your social media accounts across popular platforms such as Facebook, Twitter, Instagram, LinkedIn, and TikTok. From content creation and scheduling to engagement tracking and audience growth, we ensure your 
              brand shines in the digital realm.
              </p>
            </div>
          </div>
        </div>
        <div className="col_3">
          <div className="service_box pointer">
            <div className="icon">
              <i class="fa fa-image"></i>
            </div>
            <div className="service_meta">
              <h1 className="service_text">Photoshop</h1>
              <p className="p service_text p_color"> Dive into the world of creativity with our Photoshop service. Whether it's enhancing your blog visuals or editing photos professionally, we've got you covered. Our Photoshop expertise spans various applications, making it easy for us to be your go-to choice for all your image-related needs. Let your visual content shine with our Photoshop skills.</p>

            </div>
          </div>
        </div>
        <div className="col_3">
          <div className="service_box pointer">
            <div className="icon">
              <i class="fa fa-display"></i>
            </div>
            <div className="service_meta">
              <h1 className="service_text">Web Maintenance</h1>
              <p className="p service_text p_color">
              Keeping your website fresh and up-to-date is crucial for a successful online presence. Our Website Maintenance service is designed to take care of the nitty-gritty details, so you can focus on your core business. We provide comprehensive content updates, ensuring that your website's text, images, videos, and other media are always current, relevant, and accurate. 
             
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
