import Header from "./components/Head/Header";
import Home from "./components/Hero/Home";
import Portfolio from "./components/Portfolio/Portfolio";
import Service from "./components/Services/Service";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Scroll from "./components/Scroll";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Header />
      <Home />
      <Portfolio />
      <Service />
      <Contact />
      <Footer />
      <Scroll />
    </>
  );
}

export default App;
