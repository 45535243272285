// Portfolio.js
import React, { useState } from 'react';
import './Portfolio.css';
import ph1 from './../Images/Ball.png';
import ph2 from './../Images/cacctus.png';
import ph3 from './../Images/money.png';
import ph4 from './../Images/Car.png';
import ph5 from './../Images/illyrian.png';
import ph6 from './../Images/lost.png';
import PortfolioModal from './PortfolioModal';

const Portfolio = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedLinks, setSelectedLinks] = useState('');

  const openModal = (imageSrc, description, links) => {
    setSelectedImage(imageSrc);
    setSelectedDescription(description);
    setSelectedLinks(links)
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedDescription('');
    setSelectedLinks("");
    setModalOpen(false);
  };

  return (
    <section id="portfolio">
      <h5>
        Our <span>Work</span>
      </h5>

      <div className={`container portfolio_container ${modalOpen ? 'blurred' : ''}`}>
        <div className="container portfolio_container1">
          <article
            className="portfolio_item"
            onClick={() =>
              openModal(ph1, `Presenting "Ball It," your premier basketball destination, meticulously crafted to elevate your passion for the sport. 
              This project is a testament to our commitment to delivering a top-notch basketball experience. With "Ball It,"
               you can seamlessly secure tickets to your cherished games, enjoy live event streaming from anywhere,
                express your insights on player performances, stay informed about your team.`, `https://ball-it.netlify.app`)
            }
          >
            <div className="portfolio_item-image">
              <img src={ph1} alt="ball" />
            </div>
               <div className="portfolio_item-name1"> <p>Ball it</p>
            <i className='fa fa-arrow-right'></i>
            </div> 
          </article>
          <article
            className="portfolio_item"
            onClick={() => openModal(ph6, `
            Introducing "Lost in Space," a mesmerizing project that brings our solar system to life. This website is your gateway to exploring the planets
             and unlocking a treasure trove of information and intriguing facts about them. With its captivating design and an enchanting space theme,
              "Lost in Space" will transport you to the cosmos.`, `https://lostspace.netlify.app/` )}
          >
            <div className="portfolio_item-image">
              <img src={ph6} alt="lost" />
            </div>
            <div className="portfolio_item-name"> <p>Lost in Space</p>
            <i className='fa fa-arrow-right'></i>
            </div> 
          </article>
          <article
            className="portfolio_item"
            onClick={() => openModal(ph4, `Introducing "Rent a Car," your go-to platform for hassle-free car rentals.
             This project simplifies the rental process by allowing you to select your desired rental date,
              instantly displaying available vehicles. With a user-friendly interface, it's easy to find 
              the perfect car for your needs, making your journey a breeze. Say goodbye to complicated bookings;
               experience the future of car rentals with "Rent a Car" today!`,`https://rent-a-car-ks.netlify.app/`)}
          >
            <div className="portfolio_item-image">
              <img src={ph4} alt="car" />
            </div>
            <div className="portfolio_item-name"> <p>Rent a Car</p>
            <i className='fa fa-arrow-right'></i>
            </div> 
          </article>
        </div>
        <div className="container portfolio_container2">
          <article
            className="portfolio_item"
            onClick={() => openModal(ph2, `Discover "Cacttus Education," an educational powerhouse dedicated to empowering young minds in the field of programming. Our website showcases a wealth of information on our courses and the valuable skills they offer. With easy contact options, you can seamlessly
             connect with us via email. Join the coding revolution at "Cacttus Education" and unlock your potential in the digital world.`, `https://cacttus-itp-prizren.netlify.app/`)}
          >
            <div className="portfolio_item-image">
              <img src={ph2} alt="cacctus" />
            </div>
            <div className="portfolio_item-name4"> <p>Cacttus Education</p>
            <i className='fa fa-arrow-right'></i>
            </div> 
          </article>
          <article
            className="portfolio_item"
            onClick={() => openModal(ph3, `Welcome to "Money Converter," your trusted partner for seamless currency conversions. Our platform is designed to make international finance a breeze. With up-to-date exchange rates and a clear display of the last update, you can confidently convert currencies for your financial needs. Join us at "Money Converter" and simplify your global transactions with ease and accuracy.`, `https://currencyconverter-vue.netlify.app`)}
          >
            <div className="portfolio_item-image">
              <img src={ph3} alt="money" />
            </div>
            <div className="portfolio_item-name5"> <p>Money Converter</p>
            <i className='fa fa-arrow-right'></i>
            </div> 
          </article>
          <article
            className="portfolio_item"
            onClick={() => openModal(ph5, `Discover "IllyrianJet," your gateway to exploring the world. Our platform simplifies the ticket booking process, offering flights to destinations across the globe. With the unique "Nearest Airport" feature, we guide you to the closest airport if your desired location lacks a direct flight. Join us at "IllyrianJet" and embark on a journey to discover new horizons, one flight at a time.cd `,`https://master--illyrianjet-ks.netlify.app/` )}
          >
            <div className="portfolio_item-image">
              <img src={ph5} alt="illyrian" />
            </div>
            <div className="portfolio_item-name6"> <p>IllyrianJet</p>
            <i className='fa fa-arrow-right'></i>
            </div> 
          </article>
        </div>
      </div>
      <PortfolioModal
        isOpen={modalOpen}
        onClose={closeModal}
        imageSrc={selectedImage}
        description={selectedDescription}
        links={selectedLinks}
      />
    </section>
  );
};

export default Portfolio;
